<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading" cancelText="取消" okText="保存" width="32.5%" @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form">
      <a-form-item v-for="(item, index) of formData" :key="index" 
        :label="item.label" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]" />
        <div v-if="item.type === 'upload'" style="display: inline-block; position: relative;" @click="showImagesModal(item.name)">
          <div v-if="imageUrl">
            <LImg :src="imageUrl" style="width: 100px; height: 100px;"/>
            <div class="close" @click="closeImg($event)">&times;</div>
          </div>
          <a-button v-else type="dashed" icon="file-image">选择图片</a-button>
        </div>
        <div v-if="item.type === 'upload2'" style="display: inline-block; position: relative;">
          <a-button type="dashed" icon="file-image" @click="showImagesModal(item.name)">选择图片</a-button>
          <div style="display: flex; flex-wrap: wrap;">
            <div style="position: relative; margin: 0 10px 10px 0;" v-for="(img, index) of imageUrls" :key="index">
              <LImg :src="img" style="width: 100px; height: 100px;"/>
              <div class="close" @click="closeImg2($event, index)">&times;</div>
            </div>
          </div>
        </div> 
        <a-date-picker v-if="item.type === 'date'" style="width: 100%"  v-decorator="[item.name, { rules: item.rules }]" format="YYYY-MM-DD"/>
        <a-select v-else-if="item.type === 'select'" placeholder="请选择"
           v-decorator="[item.name, { rules: item.rules }]">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>

        <a-radio-group v-else-if="item.type === 'radio'" placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]">
          <a-radio v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-radio>
        </a-radio-group >

        <a-select v-else-if="item.type === 'select2'" mode="multiple" placeholder="请选择"
           v-decorator="[item.name, { rules: item.rules }]">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>
        <a-textarea v-else-if="item.type === 'textarea'" :rows="4"
           v-decorator="[item.name, { rules: item.rules }]"/>
      </a-form-item>
    </a-form>
    <ImageModal v-if="imagesVisible"/>
  </a-modal>
</template>

<script>
const formData = [
  {
    name: 'image_avatar',
    label: '员工头像',
    type: 'upload',
    rules: [{ required: true, message: '请上传员工头像!' }]
  },
  {
    name: 'realname',
    label: '员工名字',
    type: 'input',
    rules: [{ required: true, message: '请输入员工名字!' }]
  },
  {
    name: 'telphone',
    label: '手机号码',
    type: 'input',
    disabled:true,
    rules: [{ required: true, message: '请输入手机号码!' }]
  },
  {
    name: 'gender',
    label: '员工性别',
    type: 'radio',
    rules: [{ required: true, message: '请选择员工性别!' }],
    items: {
      data: 'sexs',
      label: 'label',
      value: 'value'
    }
  },
  // {
  //   name: 'department',
  //   label: '所属部门',
  //   type: 'select',
  //   rules: [],
  //   items: {
  //     data: 'classCategorys',
  //     label: 'studio_name',
  //     value: 'studio_id'
  //   }
  // },
  // {
  //   name: 'role',
  //   label: '账户角色',
  //   type: 'select2',
  //   rules: [{ required: true, message: '请选择账户角色!' }],
  //   items: {
  //     data: 'roles',
  //     label: 'filter_name',
  //     value: 'role_id'
  //   }
  // },
  // {
  //   name: 'tags',
  //   label: '个人标签',
  //   type: 'input',
  //   rules: []
  // },
  {
    name: 'remark',
    label: '员工简介',
    type: 'textarea',
    rules: []
  },
  {
    name: 'images',
    label: '个人图片(多图)',
    type: 'upload2',
    rules: []
  },
]
import C_ITEMS from '@/utils/items'
import url from '@/utils/URL'
import createFilePath from '@/utils/tools'
import ImageModal from '@/views/modal/images'
export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object
  },
  provide() {
    return {
      parent: this
    }
  },
  components: {
    ImageModal,
  },

  data() {
    return {
      btn_loading:false,
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      ModalTitle: '账户信息',
      visible: false,
      confirmLoading: false,
      formData,
      form: this.$form.createForm(this),
      seleteItems: {
        sexs: C_ITEMS.sexs,
        classCategorys: [],
        roles: []
      },
      imageRoot: url.imageRoot,
      imageUrl: '',
      imageUrls: [],
      imagesVisible: false,
      imageKey: '',

      detail: {}
    }
  },

  async created() {
    this.visible = true
    // await this.getRoles()
    await this.$nextTick()
    if (this.item) {
      let item = Object.assign({}, this.item)
      let formFields = {}

      for (let d of formData) {
        // if (d.name === 'role') {
        //   let arr = []
        //   for (let k in item[d.name]) {
        //     arr.push(k)
        //   }
        //   item[d.name] = arr
        // }
        if (d.name === 'tags') {
          item[d.name] = item[d.name].join(',')
        }
        if (['image_avatar', 'images'].indexOf(d.name) === -1) {
          formFields[d.name] = item[d.name] || ''
        }
      }
      this.imageUrl = item.image_avatar
      this.imageUrls = item.images
      this.form.setFieldsValue(formFields)
    }
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    closeImg(e) {
      e.stopPropagation()
      this.imageUrl = ''
    },
    
    closeImg2(e, index) {
      e.stopPropagation()
      this.imageUrls.splice(index, 1)
    },

    showImagesModal(imageKey) {
      this.imageKey = imageKey
      this.imagesVisible = true
    },
    hideImagesModal(image) {
      if (image) {
        if (this.imageKey === 'images') {
          this.imageUrls.push(image.path)
        } else {
          this.imageUrl = image.path
        }
      }
      this.imagesVisible = false
    },
    // async getRoles() {
    //   let res = await this.$store.dispatch('searchRoleAction', {})
    //   this.seleteItems.roles = res.data
    // },
    async handleOk() {
      this.confirmLoading=true;
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if (params) {
          if (params.tags) {
            params.tags = params.tags.split(',')
          }
          params.image_avatar = this.imageUrl
          params.images = this.imageUrls 
          this.form_loading=true;
          let res = await this.$store.dispatch('accoutUpdateAction', { data: params })
          if(res) {
            form.resetFields()
            this.parent.hideEditModal(1);
            this.$message.success('操作成功!');
          }
          this.confirmLoading = false;
        }
      } catch {
        this.confirmLoading = false;
      }
    },
    handleCancel() {
      this.parent.hideEditModal(0)
    },
    
    // showImagesModal(imageKey) {
    //   this.imageKey = imageKey
    //   this.imagesVisible = true
    // },
    // hideImagesModal(image) {
    //   if (image) {
    //     console.log(image)
    //     this.imageUrl = image.path
    //     this.imagePath = image.path
    //   }
    //   this.imagesVisible = false
    // },
  }
}
</script>
