<template>
  <a-card class="funtions" title="常用功能">
    <a-row class="funtions-group">
      <a-col class="funtions-panel" span="6"
        v-for="(func, index) of data" :key="index" @click="handleClick(func)">
        <router-link :to="func.shortcut_path">
          <a class="item">
            <div class="icon" :class="func.icon">
              <svg style="width:48px;height:48px;" aria-hidden="true">
                <use :xlink:href="func.shortcut_icon"></use>
              </svg>
            </div>
            <div class="name">
              <div>
                {{ func.shortcut_name }}
              </div>
              <div>{{func.shortcut_desc}}</div>
              <div class="number"><a-badge :count="func.shortcut_notice" /></div>
            </div>
            <!-- <div class="number"><a-badge :count="func.shortcut_notice" /></div> -->
          </a>
        </router-link>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>

export default {
  name: 'Functions',
  inject: ['parent'],
  props: {
    data: Array
  },

  data() {
    return {
    }
  },
  mounted () {

  },
  methods: {
    handleClick(val) {
      this.parent.getCount()
    }
  },
}
</script>
