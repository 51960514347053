<template>
  <div class="side-top">
    <div class="info">
      <!--  -->
      <a-avatar :size="56" :src="detail.brand_info.app_avatar" />
      <div class="name">{{ detail.brand_info.app_name }}</div>
    </div>
    <!-- <div class="user">
    <a-dropdown :trigger="['click']">
        <div style="cursor: pointer;">
          <div class="name">{{ detail.realname }} <a-icon type="down"></a-icon></div>
          <div class="des" :title="roles">{{ roles }}</div>
        </div>
        <a-menu slot="overlay">
          <a-menu-item>
            <div @click="showEditModal">
              <a-icon type="user" /> <span>个人中心</span>
            </div>
          </a-menu-item>
          <a-menu-item>
            <div @click="showPasswordModal">
              <a-icon type="setting" /> <span>修改密码</span>
            </div>
          </a-menu-item>
          <a-menu-divider />
          <a-menu-item>
            <a href="javascript:;" @click="logout">
              <a-icon type="logout" /> <span>退出登录</span>
            </a>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div> -->
      <editModal v-if="editVisible" :item="modalData"/>
      <passwordModal v-if="passwordVisible" :item="modalData"/>
  </div>
</template>
<style lang="scss" scoped>
.des {
  overflow: hidden;text-overflow: ellipsis;white-space: nowrap;
}
</style>

<script>
import url from '@/utils/URL'
import editModal from './editModal'
import passwordModal from './passwordModal'
export default {
  provide() {
    return {
      parent: this
    }
  },

  components: {
    editModal,
    passwordModal,
  },
  data() {
    return {
      detail: {
        brand_info: {}
      },
      imageRoot: url.imageRoot,
      editVisible: false,
      passwordVisible: false,
    }
  },
  mounted() {
    this.getList()
  },
  computed: {
    roles() {
      let strArr = []
      let roles = this.detail.role
      if (roles) {
        for (let k in roles) {
          strArr.push(roles[k])
        }
      }
      return strArr.join(',')
    }
  },
  methods: {
    async getList() {
      let res = await this.$store.dispatch('accoutAction', {})
      console.log(res.data)
      this.detail = res.data
    },
    async logout() {
      let res = await this.$store.dispatch('logoutAction', {})
      if (res) {
        this.$router.push('/user/login')
      }
    },
    showEditModal() {
      this.modalData = this.detail
      this.editVisible = true
    },
    hideEditModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.editVisible = false
    },
    showPasswordModal() {
      this.modalData = this.detail
      this.passwordVisible = true
    },
    hidePasswordModal(type) {
      this.passwordVisible = false
    },
  }
}
</script>

<style lang="scss" scoped>
.side-top {
  padding: 10px;
  color: #fff;
  text-align: center;
  line-height: 20px;
  background: #333;
  .info {
    .name {
      padding: 5px 0;
    }
  }
}
</style>
