<template>
  <a-layout class="container">
    <a-layout-sider @click="hideMessage" style="max-width:180px;min-width:180px;"  class="side" :trigger="null" collapsible v-model="collapsed">
      <perfect-scrollbar class="scrollbar">
        <sideTop />
        <l-menu  :routerKey='routerKey' :routerData="routerData" :parentKey='parentKey' :menuopenKeys='menuopenKeys' :selectKeys="selectKeys"/>
      </perfect-scrollbar>
    </a-layout-sider>
    <a-layout class="content">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-layout-header @click="hideMessage" class="header">
        <a-form class="top-search-panel">
          <a-menu mode="horizontal" v-model="menusKey" @click="handleChange">
            <a-menu-item :id="`menu-${index}`" style='padding:0 10px' @mouseover="changeOver(index)" @mouseout="changeOut" v-if="item.router.length == 0?false:true" v-for="(item,index) in routesMenus" :key="index">
              <router-link :to="`${item.path}`">
                <a-badge :dot="item.path == '/manager/BizParam' && app_sms_count < 100">
                <div style="display:flex;align-items:center">
                  <svg v-show='hoverKey == index?false:true' style="width:14px;height:14px;margin-right:10px;" :fill="menusKey[0] == index? '#00cca2':'#333'" aria-hidden="true">
                    <use :xlink:href="item.icon"></use>
                  </svg>
                  <svg v-show='hoverKey == index?true:false' style="width:14px;height:14px;margin-right:10px;" :fill="hoverKey == index? '#00cca2':'#333'" aria-hidden="true">
                    <use :xlink:href="item.icon"></use>
                  </svg>
                    <span>{{item.title}}</span>
                </div>
                </a-badge>
              </router-link>
            </a-menu-item>
          </a-menu>
        </a-form>
        <!-- <div class="top-user-info" style="display: flex; align-items: center; line-height:0">
          <a-button @click="toPath" icon="rollback" shape="round" size="small">
            回旧版本
          </a-button>
        </div> -->
        <div class="top-user-info" style="display: flex; align-items: center; line-height:0">
          <span style="margin-right: 20px;">
            <a-popover v-model="QRvisible" trigger="click">
              <div slot="content">
                <div id="qrcode" ref="qrcode"></div>
                <a-divider />
                <div style="text-align:center;margin-top:8px;cursor:pointer" >
                    <a href="javascript:;" v-clipboard:copy="detail.brand_info.app_qrcode_url" v-clipboard:success="onCopy" v-clipboard:error="onError" align="center">复制链接</a>
                </div> 
              </div>
              <a-icon :style="{fontSize:'16px'}" type="qrcode" />
            </a-popover>
          </span>
          <!-- <span style="margin-right: 20px;">
            <a-icon :style="{fontSize:'16px'}" type="question-circle" />
          </span> -->

          <span @click.stop="showMessage" style="margin-right: 20px;">
            <a-badge :count="unread">
              <a-icon :style="{fontSize:'16px'}" type="bell" />
            </a-badge>
          </span>
          
          <div class="user" style="display: inline-block;">
            <a-dropdown :trigger="['click']">
                <div style="cursor: pointer; line-height: 20px; padding: 15px 0;">
                  <div class="name" style="text-align: right;">
                    <span>
                      <img style="width:30px;height:30px;border-radius:50%;margin-right:10px" :src="detail.image_avatar">
                      <span>{{ detail.realname }}</span>
                    </span>
                  </div>
                </div>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <div @click="showEditModal">
                      <a-icon type="user" /> <span>账户信息</span>
                    </div>
                  </a-menu-item>
                  <a-menu-item>
                    <div @click="showPasswordModal">
                      <a-icon type="setting" /> <span>修改密码</span>
                    </div>
                  </a-menu-item>
                  <a-menu-divider />
                  <a-menu-item>
                    <a href="javascript:;" @click="logout">
                      <a-icon type="logout" /> <span>退出登录</span>
                    </a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          <!-- <span @click="logout">
            <a-icon type="logout" /> <span>退出登录</span>
          </span> -->
        </div>
      </a-layout-header>
      <a-layout-content @click="hideMessage">
          <div class="main">
            <a-alert
              v-if="notice_msg"
              class="main-a-alert"
              type="success"
              show-icon
              :message="notice_msg"
              banner
              closable
            >
              <template slot="icon">
                <a-icon type="info-circle" />
              </template>
            </a-alert>
            <router-view />
          </div>
      </a-layout-content>
      <a-layout-footer @click="hideMessage" class="footer">
        Copyright © 2017-{{moment().format('YYYY')}} 上海白鹿信息科技有限公司 版权所有 All Rights Reserved 沪ICP备14040613号-4
      </a-layout-footer>
    </a-layout>
      <editModal v-if="editVisible" :item="modalData"/>
      <message v-if='isMessage'/>
      <passwordModal v-if="passwordVisible" :item="modalData" isEmployee='1'/>
  </a-layout>
</template>

<script>

import moment from 'moment'
import conf from '@/utils/const'
import sideTop from './sideTop'
import editModal from './editModal'
import message from './message'
import DasFunctions from '@/views/dashboard/functions'
import passwordModal from './passwordModal'
import QRCode from 'qrcodejs2'
import infiniteScroll from 'vue-infinite-scroll'
import watermark from 'watermark-dom'
export default {
  directives: { infiniteScroll },
  name: 'Layout',
  provide() {
    return {
      parent: this,
      routesMenus: this
    }
  },
  components: {
    sideTop,
    message,
    editModal,
    passwordModal,
    DasFunctions
  },
  data(){
    return {
      loading:false,
      detail: {
        brand_info: {}
      },
      collapsed: false,
      keywords: '',
      QRvisible:false,
      QRvisibleF:1,
      editVisible: false,
      passwordVisible: false,
      manssageVisible: false, 
      isMessage:false,
      unread:0,
      messlist:[],
      busyScroll: false,
      messLoadding:false,
      searchParams: {
        "page": 1,
        "per-page": 2,
        "search": {},
        "sort": ''
      },
      menuopenKeys:[],
      selectKeys:[],
      routerKey:[],
      menusKey:[0],
      hoverKey:-1,
      routesMenus:this.$store.getters.routesMenus,
      routerData:[],
      parentKey:'work',
      timer:'',
      notice_msg:''
    }
  },

  created() {
    this.getList()
    this.getMessageUnread()
    this.routerKey = [`${this.$route.path}`]
    if(!sessionStorage.getItem("menusKey")){
      sessionStorage.setItem("menusKey",0)
      this.routerData = this.routesMenus[0].router
    }else{
      this.menusKey = [Number(sessionStorage.getItem("menusKey"))]
      let index = Number(sessionStorage.getItem("menusKey"))
      this.routerData = this.routesMenus[index].router
      this.parentKey = this.routesMenus[index].parentKey
      let obj = {
        key: Number(sessionStorage.getItem("menusKey"))
      }
      this.handleChange(obj,this.routerKey)
    }
  },
  computed: {
    roles() {
      let strArr = []
      let roles = this.detail.role
      if (roles) {
        for (let k in roles) {
          strArr.push(roles[k])
        }
      }
      return strArr.join(',')
    },
    app_sms_count(){
      return this.$store.getters.brand_info.app_sms_count
    }
  },

  methods: {
    moment,
    async getList() {
      this.loading = true
      let res = await this.$store.dispatch('accoutAction', {})
      this.detail = res.data
      this.app_sms_coun = res.data.brand_info.app_sms_coun
      watermark.init({ watermark_txt: this.detail.realname ,watermark_fontsize:'14px',watermark_alpha:0.05,})
      this.loading = false
    },
    async logout() {
      sessionStorage.setItem("menusKey",0)
      let res = await this.$store.dispatch('logoutAction', {})
      if (res) {
        clearInterval(this.timer)
        this.$router.push('/user/login')
      }
    },
    async getMessageUnread() {
      let that = this
      let res = await that.$store.dispatch('notificationUnreadAction', {})
      that.unread = res.unread
      that.notice_msg = res.notice_msg
      let obj = {...res.crm,...res.education,...res.work}
      await this.$store.dispatch('setCount', obj)
      this.timer = setInterval(async ()=>{

        let res = await that.$store.dispatch('notificationUnreadAction', {})
        that.unread = res.unread
        obj = {...res.crm,...res.education,...res.work}
        await this.$store.dispatch('setCount', obj)

      },60000)
    },
    async getMessageList() {
      this.messLoadding = true;
      let res = await this.$store.dispatch('notificationIndexAction', { data: this.searchParams })
      this.messlist = res.items;
      this.messLoadding = false;
    },
    showEditModal() {
      this.modalData = this.detail
      this.editVisible = true
    },
    hideEditModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.editVisible = false
    },
    visibleChange(visible){
      if(visible){
        if(this.messlist.length == 0){
          this.getMessageList()
        }
      }
    },
    loadMore(){
      this.$message.warning('Infinite List loaded all');
    },
    showPasswordModal() {
      this.modalData = this.detail
      this.passwordVisible = true
    },
    hidePasswordModal(type) {
      this.passwordVisible = false
    },
    toSearch(e) {
      if (e.keyCode === 13) {
        if (this.keywords) {
          this.$router.push(`/work/search/${this.keywords}`)
          this.keywords = ''
        }
      }
    },

    async handleChange(val,num){
      console.log(val,num)
      sessionStorage.setItem("menusKey",val.key)
      let setList = this.routesMenus[8].router.children
      if(num){
        if(val.key == 8){
          let res = await this.$store.dispatch('employeeGroupTypeAction', {})
          setList.forEach((item,index)=>{
              if(item.path == 'role'){
                item.children = res.data.map(it => {
                  return {
                    name: 'role-auth',
                    path: `role-auth/${it.type_id}/${it.name}`,
                    meta: {
                      title: it.name
                    }
                  }
                })
              }
          })
        }
        if(val.key == 2){
          this.getCount()
        }
      }else{
        localStorage.setItem('menuIndex',0)
        this.routerData = this.routesMenus[val.key].router
        this.routerKey = [ this.routesMenus[val.key].path ]
        this.parentKey = [ this.routesMenus[val.key].parentKey ]
        if(val.key == 2){
          this.getCount()
        }
        if(val.key == 8){
          let res = await this.$store.dispatch('employeeGroupTypeAction', {})
          setList.forEach((item,index)=>{
              if(item.path == 'role'){
                item.children = res.data.map(it => {
                  return {
                    name: 'role-auth',
                    path: `role-auth/${it.type_id}/${it.name}`,
                    meta: {
                      title: it.name
                    }
                  }
                })
              }
          })
        }
      }
    },
    async getCount(){
      // let res = await this.$store.dispatch('todoCountAction', {})
      // await this.$store.dispatch('setCount', res.data)
    },
    changeOver(e){
      this.hoverKey = e
    },
    changeOut(){
      this.hoverKey = -1
    },
    onCopy(){
      this.$message.success('已复制到剪切板!')
    },
    onError(){
      this.$message.error('操作失败!')
    },
    showMessage(){
      this.isMessage = true
    },
    hideMessage(){
      this.isMessage = false
    },
    updateUnread(){
      this.getMessageUnread()
    },
    handleMenkeyChange(val){
      this.selectKeys = val
    },
    toPath(){
      if (process.env.NODE_ENV === "development") {
        window.location.href = `https://test.wedots.cn/sso?access_token=${this.$ls.get(conf.ACCESS_TOKEN)}`
      }else {
        window.location.href = `https://cloud.wedots.cn/sso?access_token=${this.$ls.get(conf.ACCESS_TOKEN)}`
      }
      
    }

  },
  updated () {
    this.$nextTick(() => {
      if(this.QRvisible){
        if(this.QRvisibleF == 1){
          this.QRvisibleF++
          var canvas = this.$refs.qrcode;
          new QRCode(canvas, {
            text:this.detail.brand_info.app_qrcode_url, 
            width:200,
            height:200,
            colorDark : "#000",   //二维码颜色
            colorLight : "#ffffff"   //二维码背景色
          })
        }
      }
    })
    
  }
}
</script>
<style lang="scss">
  .top-search-panel{
    .ant-menu-horizontal{
      border: none;
      .ant-menu-item-selected{
        border: none;
      }
      .ant-menu-submenu{
        border-bottom: none;
      }
      .ant-menu-item-active{
        border: none;
      }
    }
    .ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu{
      border-bottom: none;
    }
  }
  .layoutContainer{
    .ant-popover-title{
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ant-popover-inner-content{
      padding: 0 6px;

    }
    .list-infinite-container{
      overflow: auto;
      max-height: 300px;
    }
  }
  .container{
    .ant-layout-header{
      box-shadow: 0px 5px 5px #ddd;
      position: relative;
      z-index: 1000;
    }
    .ant-badge-count{
      box-shadow: none;
    }
    .side{
      .ant-menu-inline{
        width: 100%;
        padding-left:2px
      }
      .ant-menu-item-selected{
        background-color: #333;
        box-shadow: -2px 0px 0px #00cca2;
      }
      .ant-menu-item{
        margin: 0px;
      }
      .ant-menu-item:hover{
        background-color: #333;
      }
    }
  }
  .main-a-alert{
    margin-top: 10px;
    .ant-alert-icon{
      top:inherit;
    }
  }
</style>
