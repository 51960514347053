<template>
    <div
        class="message-box"
        v-infinite-scroll="handleInfiniteOnLoad"
        :infinite-scroll-disabled="busy"
        :infinite-scroll-distance="10">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-list :data-source="list">
            <a-list-item slot="renderItem" :class="activeIndex == index?'message-items':''" @mouseover="handleOver(index)" @mouseout="handleOut" slot-scope="item, index">
                <a-row style='width:100%'>
                    <a-col :span="24">
                        <div>
                            <img :src="item.notification_account.notification_avatar" style="width:24px;height:24px;border-radius:50%;margin-right:8px">
                            <a-badge v-if='item.notification_seen==0' color="red">
                                <span>{{item.notification_account.notification_name}}</span>
                            </a-badge>
                            <span v-else >{{item.notification_account.notification_name}}</span>
                            <!-- <span>{{item.notification_time}}</span> -->
                        </div>
                    </a-col>
                    <a-col :span="22" offset="2">
                        <div><a-badge color="0" :text="item.notification_title" /></div>
                    </a-col>

                    <a-col :span="24">
                        <div v-if='activeIndex == index && item.notification_seen==0' style="text-align:right;cursor:pointer;color:#00cca2" @click="handleUnread(item)">标记已读</div>
                        <div v-else style="text-align:right;color:#888;font-size:12px">{{item.notification_time}}</div>
                    </a-col>
                </a-row>
            </a-list-item>
        </a-list>
    </div>
</template>

<script>
import infiniteScroll from 'vue-infinite-scroll'
    export default {
        name:'messList',
        directives: { infiniteScroll },
        inject: ['parent'],
        data() {
            return {
                loading:false,
                busy:false,
                list : [],
                pageParams: {
                    'page': 0,
                    'per-page':10,
                },
                activeIndex:-1,
            }
        },
        created () {
            this.getList(this.pageParams)
        },
        methods: {
            async getList(obj){
                this.loading = true
                let res = await this.$store.dispatch('notificationIndexAction', obj)
                if(this.list.length == 0){
                    this.list = res.items
                }else{
                    if(res.items.length == 0){
                        this.$message.warning('没有更多数据了!')
                        this.busy = true
                    }else{
                        this.list = this.list.concat(res.items)
                    }
                }
                this.loading = false;
            },
            handleOver(index){
                this.activeIndex = index
            },
            handleOut(){
                this.activeIndex = -1
            },
            async handleUnread(item){
                let res = await this.$store.dispatch('notificationSeenAction', {notification_id:item.notification_id})
                this.$message.success('操作成功!')
                this.pageParams.page = 1
                this.list = []
                this.getList(this.pageParams)
                this.parent.updateUnread()
            },
            handleInfiniteOnLoad() {
                if(this.pageParams.page != 0){
                    if(!this.busy){
                        this.getList(this.pageParams);
                    }
                }
                this.pageParams.page++
            },
        },
    }
</script>

<style lang="scss">
    .message-box{
        height: 100%;
        overflow:auto;
    }
    .message-items{
        background: #e6fff6;
    }
</style>