<template>
    <div @click.stop>
        <a-drawer
        title="通知中心"
        placement="right"
        :visible="visible"
        @close="onClose"
        :mask='false'
        width='300px'
        wrapClassName="message"
        >
            <div class="message-warp"> 
                <messList v-if="isMessList" />
            </div>
            <!-- <div style="color:#00cca2">
                <a :style="{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'center',
                zIndex: 4002,
                }"
                @click="handleAll">全部标记为已读</a>
            </div> -->
            <div style="color:#00cca2">
                <a :style="{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'center',
                zIndex: 4002,
                }"
                @click="toPath">进入通知中心</a>
            </div>
        </a-drawer>
    </div>
</template>
<script>
import messList from './messList'
    export default {
        name:"message",
        inject: ['parent'],
        provide() {
            return {
            parent: this
            }
        },
        components:{messList},
        data() {
            return {
                visible: false,
                list:[],
                isMessList:true
            }
        },
        created () {
            this.visible = true
        },
        beforeDestroy () {
            this.visible = false
        },
        methods: {
            onClose() {
                this.parent.hideMessage()
            },
            updateUnread(){
                this.parent.updateUnread()
            },
            async handleAll(){
                this.isMessList = false
                let res = await this.$store.dispatch('notificationSeenAllAction', {})
                this.isMessList = true
                this.parent.updateUnread()
                this.$message.success('操作成功!')
            },
            toPath(){
                this.$router.push({path:'/work/notification'})
            }
        },
    }
</script>

<style lang="scss">
    .message{
        .ant-drawer-body{
            height: 95%;
            padding: 12px 12px;
            .message-warp{
                height: 100%;
                .ant-list-item{
                    padding: 12px;
                }
            }
        }
    }
</style>