var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{on:{"click":function($event){$event.stopPropagation();}}},[_c('a-drawer',{attrs:{"title":"通知中心","placement":"right","visible":_vm.visible,"mask":false,"width":"300px","wrapClassName":"message"},on:{"close":_vm.onClose}},[_c('div',{staticClass:"message-warp"},[(_vm.isMessList)?_c('messList'):_vm._e()],1),_c('div',{staticStyle:{"color":"#00cca2"}},[_c('a',{style:({
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'center',
            zIndex: 4002,
            }),on:{"click":_vm.toPath}},[_vm._v("进入通知中心")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }